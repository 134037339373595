import React from 'react'
import LoginForm from 'widgets/LoginForm/LoginForm'

export default function Login() {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <LoginForm/>
    </div>
  )
}
