import Control from 'pages/Control/Control'
import Login from 'pages/Login'
import Parser from 'pages/Parser/Parser'
import Root from 'pages/Root'
import Api from 'pages/Api'



export const privateRoutes = [
    // {path: '/main', page: Main},
    {path: '/parser', page: Parser},
    {path: '/api-panel', page: Api},
    {path: '/control', page: Control},
    {path: '/root', page: Root},
    {path: '*', page: Control},

]


export const publicRoutes = [
    {path: '/login', page: Login},
    {path: '*', page: Login},
]