export const AxiosConfig = {
    domain: 'parser.uitdep.ru',
    secret_string: 'sruhgiuerfwfpq_elrgekr_dwjlfbwle',
    http_header: 'https://'
}


// export const AxiosConfig = {
//     domain: '127.0.0.1:8000',
//     secret_string: 'sruhgiuerfwfpq_elrgekr_dwjlfbwle',
//     http_header: 'http://'
// }
