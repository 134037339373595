import { Routes, Route } from 'react-router-dom'
import React, { useContext } from 'react'
import { privateRoutes, publicRoutes } from 'app/router';
import { AuthContext } from 'shared/context';

export const  AppRouter = () =>  {

const {isAuth} = useContext(AuthContext)


  return (
    isAuth.auth === true
    ?
    <Routes>
        {
            privateRoutes.map(route => (
              <Route path={route.path} element={route.page()} key={route.path} />
            ))
        }
    </Routes>
    :
    <Routes>
    {
        publicRoutes.map(route => (
          <Route path={route.path} element={route.page()} key={route.path} />
        ))
    }
    </Routes>
  )
}

